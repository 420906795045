<template>
  <!-- <van-nav-bar title="缴费列表"></van-nav-bar> -->
  <div class="top">
    <div class="container">
      <div style="height: 10px"></div>
      <div class="student_info">
        <div class="info">
          <van-button
            class="changeBtn"
            plain
            size="mini"
            type="primary"
            @click="toChild"
            color="#1989fa"
            >切换</van-button
          >
          <img src="../assets/default_profile.png" />
          <div class="student_desc">
            <span>{{ child_name }}</span>
            <span>{{ school_name }}</span>
            <span>{{ class_name }}</span>
          </div>
        </div>
      </div>
    </div>
    <!-- <van-tabs v-model:active="active" type="card"> -->
    <van-tabs v-model:active="active" color="#1989fa" @click-tab="onClickTab">
      <van-tab title="待缴费" name="1"></van-tab>
      <van-tab title="缴费成功" name="4"></van-tab>
      <van-tab title="线下缴费" name="6"></van-tab>
    </van-tabs>
</div>
  <van-empty v-if="listSize == 0" description="暂无内容" />
  <div v-if="listSize > 0" style="margin-bottom: 40px">
    <van-list
      v-model:loading="loading"
      :finished="finished"
      finished-text="没有更多了"
      offset="10"
      @load="onLoad"
    >
      <van-cell-group
        inset
        v-for="item in list"
        :key="item.payment_id"
        style="margin-top: 5px"
        @click="toDetail(item)"
      >
        <van-cell :title="item.create_time" :value="payText" />
        <van-cell :title="item.title" :value="'¥' + item.price" />
      </van-cell-group>
    </van-list>
  </div>
</template>

<script>
import { onMounted, ref } from "vue";
import { Toast } from "vant";
import { useRouter } from "vue-router";
// import { useRoute } from "vue-router";
// import axios from "axios";
import api from "../service/api";
import { safeName } from '../common/js/utils';

// import { useStore } from "vuex";

export default {
  setup() {
    const active = ref(0);
    const list = ref([]);
    const loading = ref(false);
    const finished = ref(false);
    // const route = useRoute(); //获取到值
    const router = useRouter(); //路由传值
    const payType = ref(1);
    const child_id = ref("");
    const child_name = ref("");
    const school_name = ref("");
    const class_name = ref("");
    const currentPage = ref(1);
    const payText = ref("未缴费");
    const listSize = ref(1);
    
    onMounted(() => {     
      if (getQuery("child_id")) {
        localStorage.setItem("childId", getQuery("child_id"));
        localStorage.setItem("childName", safeName(getQuery("child_name")));
        localStorage.setItem("schoolName", getQuery("school_name"));
        localStorage.setItem("className", getQuery("class_name"));
      }
      child_id.value = localStorage.getItem("childId");
      child_name.value = localStorage.getItem("childName");
      school_name.value = localStorage.getItem("schoolName");
      class_name.value = localStorage.getItem("className");
    });

    const toDetail = (item) => {
      router.push({
        path: "/detail",
        query: {
          payment_id: item.payment_id,
          payType: payType.value,
        },
      });
    };

    const toChild = () => {
      history.pushState({}, "", `https://${window.location.host}`);
      router.push({
        path: "/child",
      });
    };

    const onClickTab = ({ name }) => {
      payType.value = name;
      currentPage.value = 1;
      switch (payType.value) {
        case "1":
          payText.value = "未缴费";
          break;
        case "4":
          payText.value = "缴费成功";
          break;
        case "6":
          payText.value = "线下缴费";
          break;
        default:
          payText.value = "";
          break;
      }
      onLoad();
    };

    const onLoad = () => {
      /**
       * code 不为空时进行请求
       */
      finished.value = false;
      loading.value = true;
      api
        .getChargeList({
          child_id: child_id.value,
          type: payType.value, //1待缴费，4缴费成功，6线下缴费
          page: currentPage.value,
        })
        .then((res) => {
          // Toast("res:" + res.data.message);
          if (res.data.code == 34000) {
            if (currentPage.value == 1) {
              list.value = [];
            }
            let result = res.data.result;
            let payList = result.list;
            for (let i = 0; i < payList.length; i++) {
              list.value.push(payList[i]);
            }
            listSize.value = list.value.length;
            // 加载状态结束
            loading.value = false;
            currentPage.value++;

            // 数据全部加载完成
            if (list.value.length >= result.total) {
              finished.value = true;
            }
          } else if (res.data.code == 20005) {
            list.value = [];
            listSize.value = 0;
            finished.value = true;
          } else {
            listSize.value = 0;
            // Toast(res.data.message);
            finished.value = true;
          }
        })
        .catch((err) => {
          finished.value = true;
          listSize.value = 0;
          Toast(err.message);
        });
    };

    function getQuery(variable) {
      var query = window.location.search.substring(1);
      var vars = query.split("&");
      for (var i = 0; i < vars.length; i++) {
        var pair = vars[i].split("=");
        if (pair[0] == variable) {
          return decodeURI(pair[1]);
        }
      }
      return false;
    }

    return {
      list,
      onLoad,
      loading,
      finished,
      toDetail,
      active,
      toChild,
      onClickTab,
      payType,
      child_id,
      child_name,
      school_name,
      class_name,
      currentPage,
      payText,
      listSize,
    };
  },
};
</script>

<style lang="less">
.top{
     position: sticky;
     top: 1px;
     z-index: 99;
.container {
  height: 140px;
  background-color: #f2f2f2;
  .student_info {
    width: 94%;
    margin: 0 auto;
    height: 120px;
    border-radius: 6px;
    background-color: #1989fa;
    box-shadow: 0 2px 5px #1989fa;

    .info {
      display: block;
      width: 92%;
      height: 120px;
      margin: 0 auto;
      .changeBtn {
        float: right;
        margin-top: 20px;
        margin-right: 10px;
      }

      img {
        width: 60px;
        height: 60px;
        border-radius: 50%;
        margin-top: 4px;
        display: inline-block;
        overflow: hidden;
        margin-top: 30px;
      }
      .student_desc {
        display: inline-block;
        margin-left: 10px;
        line-height: 20px;
        font-size: 14px;
        color: #fff;
        span {
          display: block;
          color: #fff;
          font-size: 14px;
          padding: 2px 0;
          text-align: left;
        }
      }
    }
  }
}
}

</style>
